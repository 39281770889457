import React from "react";
import icecream from "../../assets/image4.jpeg";
// import icecream1 from "../../assets/image5.jpeg";
import icecream2 from "../../assets/image6.jpeg";
import image11 from '../../assets/image11.jpeg';
import image10 from '../../assets/image10.jpeg';

const Welcome = () => {
  return (
    <div className="bg-gray-50 md:p-20 p-8">
      <div className="container mx-auto px-6 text-start">
        <h2 className="text-4xl font-bold text-pink-500 mb-6">About Us</h2>
        <div className="flex md:flex-row flex-col justify-between items-center gap-6">
          {/* Text Section */}
          <div className="flex flex-col items-start gap-4 text-justify md:w-1/2">
            <p className="text-gray-600 text-lg">
              Dip It Ice Cream is a unique and exciting way to enjoy your
              favorite frozen treat with a creative twist. Unlike traditional
              scoops or cones, Dip It Ice Cream allows you to customize your ice
              cream experience by dipping it into a variety of delicious
              coatings and toppings.
            </p>
            <ul className="list-disc list-inside text-gray-600 text-lg">
              <li>
                <strong>Customizable Flavors:</strong> Start with your choice of
                ice cream base—classic vanilla, rich chocolate, fruity sorbets,
                or even vegan options.
              </li>
              <li>
                <strong>Dip It Your Way:</strong> Select from an array of
                chocolate dips, including milk, dark, or white chocolate. Some
                locations even offer colorful candy melts for a pop of fun.
              </li>
              <li>
                <strong>Toppings Galore:</strong> Once dipped, your ice cream
                can be rolled in a variety of toppings like crushed nuts,
                sprinkles, cookie crumbles, caramel chips, or even edible
                glitter for a magical finish.
              </li>
              <li>
                <strong>Interactive Experience:</strong> Dip It Ice Cream isn’t
                just a dessert; it’s an experience. Watching the chocolate
                harden into a crispy shell is as satisfying as eating it!
              </li>
              <li>
                <strong>Perfect for Any Occasion:</strong> Whether you’re at a
                party, festival, or just out for a treat, Dip It Ice Cream
                brings a playful twist to the classic dessert.
              </li>
            </ul>
            <p className="text-gray-600 text-lg">
              This innovative dessert trend combines the fun of creativity with
              the comfort of a timeless favorite, making Dip It Ice Cream a
              must-try for anyone who loves to indulge!
            </p>
          </div>

          {/* Image Section */}
          <div className="md:w-1/2 flex justify-center">
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
              <img
                src={image11}
                alt="icecream"
                className="w-64 h-64 object-cover rounded-md"
              />
              <img
                src={icecream}
                alt="icecream"
                className="w-64 h-64 object-cover rounded-md"
              />
              <img
                src={icecream2}
                alt="icecream"
                className="w-64 h-64 object-cover rounded-md"
              />
              <img
                src={image10}
                alt="icecream"
                className="w-64 h-64 object-cover rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
