import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/common/ScrollToTop";
import Layout from "./Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import AllProduct from "./pages/AllProduct";
import Menu from "./pages/Menu";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/product" element={<AllProduct/>}></Route>
          <Route path="/menu-card" element={<Menu/>}></Route>
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
