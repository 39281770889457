import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import image1 from "../../assets/mangoVanila.jpg";
import image2 from "../../assets/image2.jpeg";
import image3 from "../../assets/white.webp";
import image4 from "../../assets/Bon Bon.jpg";
import image5 from "../../assets/MadagascarChocolate.png";
import image6 from "../../assets/Turkish1.jpg";

const ProductSection = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const products = [
    {
      id: 1,
      name: "Mango",
      description:
        "A tropical delight with the freshness of mango, perfect for a summer day.",
      price: "₹79/- (50ml), ₹99/- (100ml)",
      image: image1,
    },
    {
      id: 2,
      name: "Strawberry",
      description:
        "A fruity and refreshing strawberry burst, ideal for all ages.",
      price: "₹79/- (50ml), ₹99/- (100ml)",
      image: image2,
    },
    {
      id: 3,
      name: "White Chocolate",
      description: "A creamy indulgence of premium white chocolate flavors.",
      price: "₹79/- (50ml), ₹99/- (100ml)",
      image: image3,
    },
    {
      id: 4,
      name: "Madagascar Dark Chocolate",
      description:
        "An exotic and rich dark chocolate treat sourced from Madagascar.",
      price: "₹99/- (50ml), ₹189/- (100ml)",
      image: image4,
    },
    {
      id: 5,
      name: "Bon Bon Milk Chocolate",
      description: "A smooth and delightful milk chocolate experience.",
      price: "₹99/- (50ml), ₹189/- (100ml)",
      image: image5,
    },
    {
      id: 6,
      name: "Turkish Hazelnut Chocolate",
      description:
        "A rich chocolate treat paired with crunchy Turkish hazelnuts.",
      price: "₹189/- (50ml), ₹189/- (100ml)",
      image: image6,
    },
  ];

  return (
    <div className="bg-[#fcf3ee] md:p-20 p-8">
      {/* Section Header */}
      <div className="container mx-auto text-start mb-10">
        <h2 className="text-4xl font-bold text-pink-500 mb-4">Our Products</h2>
        <p className="text-gray-600 text-lg">
          Explore our exquisite range of chocolate and fruity delights crafted
          with love. Perfect for every occasion, these treats are guaranteed to
          satisfy your sweet cravings!
        </p>
      </div>

      {/* Product Cards */}
      <div>
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {products.map((product) => (
            <div
              key={product.id}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {product.name}
                </h3>
                <p className="text-gray-600 text-sm">{product.description}</p>
                {/* <p className="text-pink-500 font-bold mt-2">{product.price}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Show More Button */}
      <div className="flex justify-center mt-10">
        <button
          onClick={() => navigate("/product")} // Navigate to /product on click
          className="px-6 py-3 bg-pink-500 text-white font-bold rounded-lg shadow-lg hover:bg-pink-600 transition-colors duration-300"
        >
          Show More
        </button>
      </div>
    </div>
  );
};

export default ProductSection;
