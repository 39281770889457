import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaEnvelope,
  FaPhoneAlt,
} from "react-icons/fa";
import logo from "../../assets/dipit_logo.png";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        {/* Logo or Brand Name */}
        <div className="cursor-pointer">
          <img src={logo} alt="DIP IT Logo" className="h-20" />
        </div>

        {/* Address */}
        <div className="text-sm text-center md:text-left">
          <p>#25, 29th Main Road, BTM Layout 2nd Stage, Bangalore - 560076</p>
          <p>Nexus Whitefield Mall, 3rd Floor</p>
          <p>
            <FaEnvelope className="inline mr-2" />
            Email:{" "}
            <a
              href="mailto:marvind7@gmail.com"
              className="hover:text-gray-400 transition duration-200"
            >
              marvind7@gmail.com
            </a>
          </p>
          <p>
            <FaPhoneAlt className="inline mr-2" />
            Phone:{" "}
            <a
              href="tel:+919900837767"
              className="hover:text-gray-400 transition duration-200"
            >
              +91 9900837767
            </a>
          </p>
        </div>

        {/* Social Media Links */}
        <div className="flex space-x-4 mt-4 md:mt-0">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-500 transition duration-200 text-lg"
          >
            <FaFacebook />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-400 transition duration-200 text-lg"
          >
            <FaTwitter />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-700 transition duration-200 text-lg"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
