import React from "react";
import logo from "../../assets/bolmy.jpeg";

const OurJourney = () => {
  return (
    <>
      <h2 className="text-2xl md:text-3xl font-bold text-pink-500 mb-4 lg:hidden px-6 md:px-12 py-4">
        Our Journey
      </h2>
      <div className="flex flex-col md:flex-row items-center justify-center p-6 md:p-12 space-y-6 md:space-y-0 md:space-x-8">
        {/* Image Section */}

        <div className="flex-shrink-0">
          <img
            src={logo} // Replace with the actual image URL
            alt="Our Journey"
            className="w-64 h-full md:w-96 rounded-lg shadow-md"
          />
        </div>

        {/* Content Section */}
        <div className="flex-grow text-center md:text-left">
          <h2 className="text-2xl md:text-3xl font-bold text-pink-500 mb-4 md:block hidden">
            Our Journey
          </h2>
          <p className="text-gray-600 text-base md:text-lg leading-relaxed text-justify">
            Bolmay: Our trusted journey partner in driving innovation and
            delivering excellence together. We have consistently focused on
            transforming ideas into impactful solutions that empower businesses
            and inspire growth. By embracing collaboration, creativity, and a
            forward-thinking mindset, we pave the way for a brighter future.
          </p>
        </div>
      </div>
    </>
  );
};

export default OurJourney;
