import React from "react";
import icecream from '../assets/icecream18.jpg';

const ContactSection = () => {
  return (
    <div
      className="flex flex-col items-center justify-center text-center p-8 bg-orange-100"
      style={{
        backgroundColor: "#fcf3ee", // Matches the flyer’s background
        fontFamily: "'Comic Sans MS', cursive, sans-serif", // Similar to the flyer style
      }}
    >
      {/* Title Section */}
      <h1
        className="text-4xl font-bold mb-4"
        style={{
          color: "#ff6ec7",
          textShadow: "2px 2px 2px rgba(0, 0, 0, 0.3)",
        }}
      >
        DIP IT 
      </h1>
      <p className="text-2xl italic mb-6" style={{ color: "#8844ee" }}>
        Enjoy!
      </p>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row  justify-between gap-10 w-full max-w-4xl">
        {/* Left Content */}
        <div className="text-left">
          <p className="text-lg font-medium">
            <strong>Address:</strong>
            <br />
            #25, 29th Main Road, BTM Layout 2nd Stage, Bangalore - 560076
          </p>
          <p className="text-lg font-medium">
            <strong>Location:</strong>
            <br />
            Nexus Whitefield Mall, 3rd Floor
          </p>
          <p className="text-lg font-medium">
            <strong>Email:</strong>
            <br />
            marvind7@gmail.com
          </p>
          <p className="text-lg font-medium">
            <strong>Phone:</strong>
            <br />
            +91 9900837767
          </p>
        </div>

        {/* Right Image */}
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <img
            src={icecream} // Replace with the actual image URL
            alt="Location of Nexus Whitefield Mall"
            className="rounded-lg shadow-lg"
            style={{
              border: "3px solid #ffffff",
              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
            }}
          />
        </div>
      </div>

      {/* Delivery Info Section */}
      <div className="mt-8 flex flex-col items-center">
        <p className="text-xl font-semibold text-gray-800">
          Just call us or send a message
        </p>
        <p className="text-lg font-medium text-green-800">
          <strong>WhatsApp:</strong>+91 9900837767
        </p>
      </div>
    </div>
  );
};

export default ContactSection;
