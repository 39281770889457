import React from "react";
import image1 from "../../assets/banner1.jpeg";
import image2 from "../../assets/banner2.jpeg";
import image3 from "../../assets/banner3.jpeg";
import image4 from "../../assets/banner5.jpeg";
import image5 from "../../assets/banner6.jpeg";
import image6 from "../../assets/banner7.jpeg";
import image7 from "../../assets/image4.jpeg";
import image8 from "../../assets/image6.jpeg";
import image9 from "../../assets/image8.jpeg";
import image10 from "../../assets/image11.jpeg";

import { motion } from "framer-motion";

const ProductCarousel = () => {
  const products = [
    {
      id: 1,
      name: "Mango Vanilla",
      image: image1,
    },
    {
      id: 2,
      name: "Strawberry",
      image: image2,
    },
    {
      id: 3,
      name: "White Chocolate",
      image: image3,
    },
    {
      id: 4,
      name: "Bon Bon",
      image: image4,
    },
    {
      id: 5,
      name: "Madagascar Chocolate",
      image: image5,
    },
    {
      id: 6,
      name: "Turkish Hazelnut",
      image: image6,
    },
    {
      id: 7,
      name: "Turkish Hazelnut",
      image: image7,
    },
    {
      id: 8,
      name: "Turkish Hazelnut",
      image: image8,
    },
    {
      id: 9,
      name: "Turkish Hazelnut",
      image: image9,
    },
    {
      id: 10,
      name: "Turkish Hazelnut",
      image: image10,
    },
  ];

  return (
    <div className="bg-gray-50 p-8">
      <h2 className="text-start text-3xl font-semibold mb-6 text-pink-500">Our Store</h2>
      <div className="overflow-hidden">
        <motion.div
          className="flex space-x-6"
          animate={{
            x: ["0%", "-100%"],
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 40, // Adjust speed here
              ease: "linear",
            },
          }}
        >
          {[...products, ...products].map((product, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-64 h-64 bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default ProductCarousel;
