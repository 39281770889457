import React from "react";
import image1 from "../../assets/mangoVanila.jpg";
import image2 from "../../assets/image2.jpeg";
import image3 from "../../assets/white.webp";
import image4 from "../../assets/Bon Bon.jpg";
import image5 from "../../assets/MadagascarChocolate.png";
import image6 from "../../assets/Turkish1.jpg";

import image7 from "../../assets/icecream16.jpg";
import image8 from "../../assets/icecream17.jpg";
import image9 from "../../assets/icecream18.jpg";
import image10 from "../../assets/icecream19.avif";
import image11 from "../../assets/icecream20.jpeg";
import image12 from "../../assets/icecream21.webp";

import image13 from "../../assets/icecream22.avif";
import image14 from "../../assets/icecream23.jpg";
import image15 from "../../assets/icecream12.jpeg";
import image16 from "../../assets/icecream10.jpeg";
import image17 from "../../assets/icecream11.jpeg";

const Product = () => {
  const products = [
    {
      id: 1,
      name: "Mango",
      description:
        "A tropical delight with the freshness of mango, perfect for a summer day.",
      price: "₹79/- (50ml), ₹99/- (100ml)",
      image: image1, // Replace with your image path
    },
    {
      id: 2,
      name: "Strawberry",
      description:
        "A fruity and refreshing strawberry burst, ideal for all ages.",
      price: "₹79/- (50ml), ₹99/- (100ml)",
      image: image2, // Replace with your image path
    },
    {
      id: 3,
      name: "White Chocolate",
      description: "A creamy indulgence of premium white chocolate flavors.",
      price: "₹79/- (50ml), ₹99/- (100ml)",
      image: image3, // Replace with your image path
    },
    {
      id: 4,
      name: "Madagascar Dark Chocolate",
      description:
        "An exotic and rich dark chocolate treat sourced from Madagascar.",
      price: "₹99/- (50ml), ₹189/- (100ml)",
      image: image4, // Replace with your image path
    },
    {
      id: 5,
      name: "Bon Bon Milk Chocolate",
      description: "A smooth and delightful milk chocolate experience.",
      price: "₹99/- (50ml), ₹189/- (100ml)",
      image: image5, // Replace with your image path
    },
    {
      id: 6,
      name: "Turkish Hazelnut Chocolate",
      description:
        "A rich chocolate treat paired with crunchy Turkish hazelnuts.",
      price: "₹189/- (50ml), ₹189/- (100ml)",
      image: image6, // Replace with your image path
    },
  ];

  const chocolate = [
    {
      id: 1,
      name: "Mango",
      description:
        "A tropical delight with the freshness of mango, perfect for a summer day.",
      price: "₹99/- (75ml), ₹199/- (100ml)",
      image: image12, // Replace with your image path
    },
    {
      id: 2,
      name: "Strawberry",
      description:
        "A fruity and refreshing strawberry burst, ideal for all ages.",
      price: "₹99/- (75ml), ₹199/- (100ml)",
      image: image11, // Replace with your image path
    },
    {
      id: 3,
      name: "White Chocolate",
      description: "A creamy indulgence of premium white chocolate flavors.",
      price: "₹99/- (75ml), ₹199/- (100ml)",
      image: image10, // Replace with your image path
    },
    {
      id: 4,
      name: "Madagascar Dark Chocolate",
      description:
        "An exotic and rich dark chocolate treat sourced from Madagascar.",
      price: "₹129/- (75ml), ₹229/- (100ml)",
      image: image9, // Replace with your image path
    },
    {
      id: 5,
      name: "Bon Bon Milk Chocolate",
      description: "A smooth and delightful milk chocolate experience.",
      price: "₹129/- (75ml), ₹229/- (100ml)",
      image: image8, // Replace with your image path
    },
    {
      id: 6,
      name: "Turkish Hazelnut Chocolate",
      description:
        "A rich chocolate treat paired with crunchy Turkish hazelnuts.",
      price: "₹129/- (75ml), ₹229/- (100ml)",
      image: image7, // Replace with your image path
    },
  ];

  const marshmallow = [
    {
      id: 1,
      name: "Mango",
      description:
        "A tropical delight with the freshness of mango, perfect for a summer day.",
      price: "₹99/- (100ml)",
      image: image13, // Replace with your image path
    },
    {
      id: 2,
      name: "Strawberry",
      description:
        "A fruity and refreshing strawberry burst, ideal for all ages.",
      price: "₹109/- (100ml)",
      image: image14, // Replace with your image path
    },
    {
      id: 3,
      name: "White Chocolate",
      description: "A creamy indulgence of premium white chocolate flavors.",
      price: "₹109/- (100ml)",
      image:image15, // Replace with your image path
    },
    {
      id: 4,
      name: "Madagascar Dark Chocolate",
      description:
        "An exotic and rich dark chocolate treat sourced from Madagascar.",
      price: "₹109/- (100ml)",
      image: image8, // Replace with your image path
    },
    {
      id: 5,
      name: "Bon Bon Milk Chocolate",
      description: "A smooth and delightful milk chocolate experience.",
      price: "₹109/- (100ml)",
      image: image16, // Replace with your image path
    },
    {
      id: 6,
      name: "Turkish Hazelnut Chocolate",
      description:
        "A rich chocolate treat paired with crunchy Turkish hazelnuts.",
      price: "₹109/- (100ml)",
      image: image17, // Replace with your image path
    },
  ];

  return (
    <div className="bg-[#fcf3ee] md:p-20 p-8">
      {/* Section Header */}
      <div className="container mx-auto text-start mb-10">
        <h2 className="text-4xl font-bold text-pink-500 mb-4">Our Products</h2>
        <p className="text-gray-600 text-lg">
          Explore our exquisite range of chocolate and fruity delights crafted
          with love. Perfect for every occasion, these treats are guaranteed to
          satisfy your sweet cravings!
        </p>
      </div>

      {/* Product Cards */}
      <div>
        <div>
          <h2 className="text-3xl font-bold text-black mb-4">Vanilla Stick</h2>
        </div>
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {products.map((product) => (
            <div
              key={product.id}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {product.name}
                </h3>
                <p className="text-gray-600 text-sm">{product.description}</p>
                {/* <p className="text-pink-500 font-bold mt-2">{product.price}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4">
        <div>
          <h2 className="text-3xl font-bold text-black mb-4">
            Chocolate Heart
          </h2>
        </div>
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {chocolate?.map((product) => (
            <div
              key={product.id}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {product.name}
                </h3>
                <p className="text-gray-600 text-sm">{product.description}</p>
                {/* <p className="text-pink-500 font-bold mt-2">{product.price}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4">
        <div>
          <h2 className="text-3xl font-bold text-black mb-4">
            Marshmallow Skewers
          </h2>
        </div>
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {marshmallow?.map((product) => (
            <div
              key={product.id}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {product.name}
                </h3>
                <p className="text-gray-600 text-sm">{product.description}</p>
                {/* <p className="text-pink-500 font-bold mt-2">{product.price}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
