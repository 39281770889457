import React, { useCallback, useEffect, useState } from "react";
import { IoArrowUndo, IoArrowRedoSharp } from "react-icons/io5";
import image1 from "../../assets/banner1.jpeg";
import image2 from "../../assets/image3.jpeg";
import image3 from "../../assets/banner3.jpeg";
import image4 from "../../assets/image8.jpeg";
import image5 from "../../assets/image11.jpeg";
import image6 from "../../assets/banner7.jpeg";
import { motion } from "framer-motion";

const Carousel = () => {
  const images = [
    {
      src: image5,
      header: "Welcome to Dip It!",
      text:
        "Indulge in the creamiest scoops, drizzled with joy. Your perfect ice cream escape starts here!",
    },
    {
      src: image2,
      header: "Flavorful Adventures Await",
      text:
        "From classic favorites to exotic creations, we've got a scoop for every craving. Come explore the magic of Dip It!",
    },
    {
      src: image3,
      header: "Scoop, Dip, Smile!",
      text:
        "At Dip It, every bite is a celebration. Treat yourself to happiness in a cone or cup today!",
    },
    {
      src: image4,
      header: "Crafted with Love",
      text:
        "Our ice creams are made with the finest ingredients and a dash of love to bring you unforgettable flavors.",
    },
    {
      src: image1,
      header: "Cool Treats, Warm Hearts",
      text:
        "Share smiles over scoops of our irresistible ice cream. Dip It—where happiness meets flavor!",
    },
    {
      src: image6,
      header: "Life's Better with Ice Cream",
      text:
        "Celebrate life's sweet moments with a swirl of your favorite ice cream. Only at Dip It!",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [leftButtonHover, setLeftButtonHover] = useState(false);
  const [rightButtonHover, setRightButtonHover] = useState(false);

  const leftHandleHoverStart = () => setLeftButtonHover(true);
  const leftHandleHoverEnd = () => setLeftButtonHover(false);
  const rightHandleHoverStart = () => setRightButtonHover(true);
  const rightHandleHoverEnd = () => setRightButtonHover(false);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToSlide = (index) => setCurrentIndex(index);

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [nextSlide]);

  return (
    <div className="relative w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] overflow-hidden">
      <div
        className="flex transition-transform duration-300 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div key={index} className="w-full flex-shrink-0 relative">
            <img
              src={image.src}
              alt={`Slide ${index + 1}`}
              className="w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40"></div>
            {index === currentIndex && (
              <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-white text-center p-4">
                <motion.h2
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.9 }}
                  className="text-[18px] sm:text-[25px] md:text-[40px] lg:text-[50px] font-bold xl:w-[45%] lg:w-[60%] md:w-[80%] tracking-wider"
                >
                  {image.header}
                </motion.h2>

                <motion.p
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1.2 }}
                  className="w-[90%] sm:w-[80%] md:w-[70%] mt-1 text-[12px] sm:text-[16px] md:text-[20px]"
                >
                  {image.text}
                </motion.p>
              </div>
            )}
          </div>
        ))}
      </div>

      <motion.button
        onClick={prevSlide}
        onMouseEnter={leftHandleHoverStart}
        onMouseLeave={leftHandleHoverEnd}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 focus:outline-none text-white hover:text-primaryHoverColor font-bold cursor-pointer"
        whileHover={{ scale: 1.1 }}
      >
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: leftButtonHover ? 0 : 10 }}
          transition={{ duration: 0.3 }}
        >
          <IoArrowUndo size={30} />
        </motion.div>
      </motion.button>

      <motion.button
        onClick={nextSlide}
        onMouseEnter={rightHandleHoverStart}
        onMouseLeave={rightHandleHoverEnd}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 focus:outline-none text-white hover:text-primaryHoverColor font-bold cursor-pointer"
        whileHover={{ scale: 1.1 }}
      >
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: rightButtonHover ? 10 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <IoArrowRedoSharp size={30} />
        </motion.div>
      </motion.button>

      <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
        {images?.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`h-3 w-3 sm:h-4 sm:w-4 rounded-full focus:outline-none ${
              currentIndex === index ? "bg-blue-600" : "bg-gray-200"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
