import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/dipit_logo 1.png";

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <nav className="sticky top-0 flex justify-between items-center px-8 py-6 bg-white shadow-md z-50">
      {/* Logo */}
      <div onClick={() => navigate("/")} className="cursor-pointer">
        <img src={logo} alt="DIP IT Logo" className="h-10" />
      </div>

      {/* Desktop Navigation Links */}
      <ul className="hidden md:flex space-x-6">
        <li>
          <div
            onClick={() => navigate("/")}
            className="text-gray-700 hover:text-pink-500 cursor-pointer"
          >
            Home
          </div>
        </li>
        <li>
          <div
            onClick={() => navigate("/about")}
            className="text-gray-700 hover:text-pink-500 cursor-pointer"
          >
            About
          </div>
        </li>
        <li>
          <div
            onClick={() => navigate("/product")}
            className="text-gray-700 hover:text-pink-500 cursor-pointer"
          >
            Product
          </div>
        </li>
        <li>
          <div
            onClick={() => navigate("/menu-card")}
            className="text-gray-700 hover:text-pink-500 cursor-pointer"
          >
            Menu
          </div>
        </li>
        <li>
          <div
            onClick={() => navigate("/contact-us")}
            className="text-gray-700 hover:text-pink-500 cursor-pointer"
          >
            Contact
          </div>
        </li>
      </ul>

      {/* Mobile Menu Toggle */}
      <div
        onClick={toggleSidebar}
        className="md:hidden text-gray-700 hover:text-pink-500 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full bg-gradient-to-r from-[#fcf3ee] to-[#dec7bb] shadow-lg z-50 w-64 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="flex justify-center p-4">
          {/* Logo inside sidebar */}
          <img src={logo} alt="DIP IT Logo" className="h-10 mb-4" />
        </div>
        <ul className="flex flex-col space-y-4 px-6">
          <li>
            <div
              onClick={() => {
                navigate("/");
                toggleSidebar();
              }}
              className="text-black font-bold text-xl hover:text-pink-500 cursor-pointer"
            >
              Home
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                navigate("/about");
                toggleSidebar();
              }}
              className="text-black font-bold text-xl hover:text-pink-500 cursor-pointer"
            >
              About
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                navigate("/product");
                toggleSidebar();
              }}
              className="text-black font-bold text-xl hover:text-pink-500 cursor-pointer"
            >
              Product
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                navigate("/menu-card");
                toggleSidebar();
              }}
              className="text-black font-bold text-xl hover:text-pink-500 cursor-pointer"
            >
              Menu
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                navigate("/contact-us");
                toggleSidebar();
              }}
              className="text-black text-xl font-bold  hover:text-pink-500 cursor-pointer"
            >
              Contact
            </div>
          </li>
        </ul>
      </div>

      {/* Overlay */}
      {isSidebarOpen && (
        <div
          onClick={toggleSidebar}
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
        ></div>
      )}
    </nav>
  );
};

export default Navbar;
