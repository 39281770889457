import React from 'react'
import Banner from '../components/common/Banner'
import ProductCarousel from '../components/Home/ProductCarousel'
import image from '../assets/banner14.jpg';
import ContactSection from '../components/ContactSection';

const ContactUs = () => {
  return (
    <div>
      <Banner pageName="Contact Us" backgroundImage={image}/>
      <ContactSection/>
      <ProductCarousel/>
    </div>
  )
}

export default ContactUs