import React from 'react'
import Carousel from '../components/Home/Carousel'
import Welcome from '../components/Home/Welcome'
import ProductCarousel from '../components/Home/ProductCarousel'
import ProductSection from '../components/Home/ProductSection'
import Testimonial from '../components/Home/Testimonial'
import OurJourney from '../components/Home/OurJourney'

const Home = () => {
  return (
    <div>
        <Carousel/>
        <Welcome/>
        <ProductSection/>
        <Testimonial/>
        <OurJourney/>
        <ProductCarousel/>
    </div>
  )
}

export default Home