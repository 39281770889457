import React from "react";
import image1 from "../assets/icecream13.jpeg";
import image2 from "../assets/icecream11.jpeg";
import image3 from "../assets/icecream19.avif";
import logo from "../assets/dipit_logo.png";

const MenuCard = () => {
  const renderMenuSection = (title, sizes, items) => {
    return (
      <div className="mb-6 px-4 md:px-16 lg:px-32">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center border-b-2 border-gray-300 pb-2">
          <h2 className="text-lg md:text-xl font-semibold text-gray-800 mb-2 md:mb-0">{title}</h2>
          <div className="flex space-x-4 md:space-x-8">
            {sizes?.map((size, index) => (
              <p key={index} className="text-sm text-gray-600">
                {size}
              </p>
            ))}
          </div>
        </div>
        <div className="mt-2">
          {items?.map((item, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row justify-between items-start md:items-center py-2 last:border-b-0"
            >
              <span className="text-gray-700 mb-2 md:mb-0">{item.name}</span>
              <div className="flex space-x-4 md:space-x-8">
                {item?.prices?.map((price, priceIndex) => (
                  <span key={priceIndex} className="text-gray-700">
                    {price}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto bg-white rounded-lg shadow-lg font-sans p-5 md:p-10 lg:p-20">
      {/* Header */}
      <div className="flex flex-col md:flex-row justify-center">
        {/* Image Column */}
        <div className="md:flex flex-row md:flex-col gap-3 w-full md:w-[30%] hidden">
          <div className="md:border-4 rounded-md md:border-red-950 overflow-hidden">
            <img src={image1} alt="Ice Cream" className="w-full h-auto" />
          </div>
          <div className="md:border-4 rounded-md md:border-red-950 overflow-hidden">
            <img src={image2} alt="Ice Cream" className="w-full h-auto" />
          </div>
          <div className="md:border-4 rounded-md md:border-red-950 overflow-hidden">
            <img src={image3} alt="Ice Cream" className="w-full h-auto" />
          </div>
        </div>
        {/* Menu Column */}
        <div className="flex-grow w-full md:w-[70%] p-5 md:px-10">
          {/* Logo */}
          <div className="flex justify-center mb-4">
            <img src={logo} alt="Dip It Logo" className="w-auto h-16 md:h-20" />
          </div>
          {/* Menu Heading */}
          <h1 className="text-xl md:text-2xl font-bold text-gray-800 text-center mb-6">
            Ice Cream Menu
          </h1>

          {/* Menu Sections */}
          {renderMenuSection(
            "Vanilla Stick",
            ["Mini", "Large"],
            [
              {
                name: "Mango/ Strawberry/White Chocolate",
                prices: ["79/-", "149/-"],
              },
              { name: "Madagascar Dark Chocolate", prices: ["99/-", "189/-"] },
              { name: "Bon Bon Milk Chocolate", prices: ["99/-", "189/-"] },
              { name: "Turkish Hazelnut Chocolate", prices: ["99/-", "189/-"] },
            ]
          )}

          {renderMenuSection(
            "Chocolate Heart",
            ["75ml", "100ml"],
            [
              {
                name: "Mango/Strawberry/White Chocolate",
                prices: ["99/-", "199/-"],
              },
              { name: "Madagascar Dark Chocolate", prices: ["129/-", "229/-"] },
              { name: "Bon Bon Milk Chocolate", prices: ["129/-", "229/-"] },
              {
                name: "Turkish Hazelnut Chocolate",
                prices: ["129/-", "229/-"],
              },
            ]
          )}

          {renderMenuSection(
            "Marshmallow Skewers",
            [],
            [
              { name: "Mango/Strawberry/White Chocolate", prices: ["99/-"] },
              { name: "Madagascar Dark Chocolate", prices: ["109/-"] },
              { name: "Bon Bon Milk Chocolate", prices: ["109/-"] },
              { name: "Turkish Hazelnut Chocolate", prices: ["109/-"] },
            ]
          )}

          {renderMenuSection(
            "Extras",
            [],
            [
              { name: "Toppings (Assorted)", prices: ["20/-"] },
              { name: "Marshmallows 1Pc", prices: ["20/-"] },
              { name: "Syrups (Assorted)", prices: ["15/-"] },
            ]
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuCard;
