import React from "react";
import { motion } from "framer-motion";
import { FaStar, FaUser } from "react-icons/fa"; // Importing the icons from react-icons

// Review data
const reviews = [
  {
    rating: 5,
    comment:
      "Dipit Chocolate's truffle gift box is a must-try! The flavors are out of this world, and the packaging is elegant.",
    name: "Arjun M.",
    date: "July 3, 2023",
  },
  {
    rating: 4,
    comment:
      "I ordered the assorted chocolate box for my corporate event, and it was a hit! Great taste and beautiful presentation.",
    name: "Priya S.",
    date: "August 15, 2023",
  },
  {
    rating: 5,
    comment:
      "Dipit Chocolate Shop never disappoints. Their dark chocolate bars are perfect for gifting and personal indulgence!",
    name: "Ravi K.",
    date: "September 22, 2023",
  },
  {
    rating: 4,
    comment:
      "Loved the quality of chocolates and the variety. The gift sets were perfect for my team, and they appreciated the eco-friendly packaging.",
    name: "Anita B.",
    date: "October 5, 2023",
  },
  {
    rating: 5,
    comment:
      "Exceptional chocolate flavors and luxurious gift options. Dipit Chocolate Shop is my go-to for all corporate gifts!",
    name: "Meera T.",
    date: "November 1, 2023",
  },
  {
    rating: 4,
    comment:
      "The chocolates are amazing, and I love the unique combinations. Would love to see more seasonal collections!",
    name: "Rajesh P.",
    date: "December 12, 2023",
  },
];

const Testimonial = () => {
  const totalWidth = reviews.length * (256 + 32); // Adjusting based on card width and spacing

  return (
    <div className="bg-gray-100 py-16 px-6">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-extrabold text-pink-600 mb-10 text-start">
          Testimonials
        </h2>
        <div className="relative overflow-hidden">
          <motion.div
            className="flex items-center space-x-8"
            animate={{
              x: [-totalWidth / 2, -totalWidth],
            }}
            transition={{
              x: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 60, // Slower movement
                ease: "linear",
              },
            }}
          >
            {/* Double the reviews for seamless loop */}
            {[...reviews, ...reviews].map((review, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-64 bg-white border border-gray-200 rounded-lg p-6 shadow hover:shadow-lg transition-shadow duration-300"
              >
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <FaStar
                      key={i}
                      className={`h-5 w-5 ${
                        i < review.rating ? "text-yellow-400" : "text-gray-300"
                      }`}
                    />
                  ))}
                </div>
                <p className="text-gray-700 mb-6 italic">"{review.comment}"</p>
                <div className="flex items-center">
                  <FaUser className="h-10 w-10 text-gray-400 mr-4" />
                  <div>
                    <p className="font-bold text-gray-900">{review.name}</p>
                    <p className="text-sm text-gray-500">{review.date}</p>
                  </div>
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
