import React from "react";
import image from "../assets/banner14.jpg";
import Banner from "../components/common/Banner";
import Product from "../components/Home/Product";
import ProductCarousel from "../components/Home/ProductCarousel";
import Testimonial from "../components/Home/Testimonial";

const AllProduct = () => {
  return (
    <div>
      <Banner pageName="Product" backgroundImage={image} />
      <Product/>
      <Testimonial/>
      <ProductCarousel/>
    </div>
  );
};

export default AllProduct;
