import React from 'react'
import ProductCarousel from '../components/Home/ProductCarousel'
import Welcome from '../components/Home/Welcome'
import Banner from '../components/common/Banner';
import image from '../assets/banner14.jpg';
import Testimonial from '../components/Home/Testimonial';

const About = () => {
  return (
    <div>
      <Banner pageName="About Dip It" backgroundImage={image}/>
      <Welcome/>
      <Testimonial/>
      <ProductCarousel/>
    </div>
  )
}

export default About