import React from "react";
import Banner from "../components/common/Banner";
import image from "../assets/banner14.jpg";
import MenuCard from "../components/MenuCard";

const Menu = () => {
  return (
    <div>
      <Banner pageName="Menu Item" backgroundImage={image} />
      <MenuCard/>
    </div>
  );
};

export default Menu;
